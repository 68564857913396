<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :rules="rules"
      :editTitle="'商品'"
      :isInline="true"
    >
      <div slot="toolSub"></div>
      <div class="search" slot="tool">
        <el-input
          placeholder="按订单号查询"
          v-model="searchQuery.partnerOrderId"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">订单号</template>
        </el-input>

        <el-input
          placeholder="按操作人查询"
          v-model="searchQuery.operatorUserName"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">操作人</template>
        </el-input>
        <el-input
          placeholder="按接收人查询"
          v-model="searchQuery.receiverUserName"
          size="small"
          class="searchInput"
        >
          <template slot="prepend">接收人</template>
        </el-input>
        
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    // let self = this;
    return {
      remote: api.common,
      apiName: "/ticketNeedOrderTransferLog",
      searchVal: "",
      statusList: [
        { label: "成功", value: 0 },
        { label: "失败", value: 1 },
      ],
      bntLoading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [3, 3, 3, 3],
        thirdSoltSpan: [14, 14, 14, 14],
      },
      searchQuery: { partnerOrderId: "", operatorUserName: "", receiverUserName: "" },
      rules: {
        //传递给APPlist的表单验证
        jobCode: [
          {
            required: true,
            message: "请输入岗位编码",
            trigger: ["blur", "change"],
          },
        ],
        jobName: [
          {
            required: true,
            message: "请输入岗位名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      props: [
        {
          label: "订单号",
          prop: "partnerOrderId",
          align: "center",
        },
        {
          label: "操作人",
          prop: "operatorUserName",
          align: "center",
        },

        {
          label: "接收人",
          prop: "receiverUserName",
          align: "center",
        },

        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
        },

        {
          label: "理由",
          prop: "reason",
          align: "center",
        },

        
      ],
      formProps: [],
    };
  },
  created() {},
  methods: {
    //同步默认商品到商户
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
