import { render, staticRenderFns } from "./transferLog.vue?vue&type=template&id=c6e7fdac&scoped=true&"
import script from "./transferLog.vue?vue&type=script&lang=js&"
export * from "./transferLog.vue?vue&type=script&lang=js&"
import style0 from "./transferLog.vue?vue&type=style&index=0&id=c6e7fdac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6e7fdac",
  null
  
)

export default component.exports